<div *ngIf="!bphChange.hide && !confChange.showDetails && !showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <span *ngIf="confChange.isRequired" style="color: red">* </span>{{confChange.tableLabel[selectedLanguage]}}
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange.dialogText)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>
  <ng-container
    *ngIf="!confChange.hasShowNodata || (confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length > 0)">
    <table class="angular-table {{bphChange.error_class}}"
           style="width: 100%;margin-bottom: 10px;margin-top: 5px">
      <thead class="angular-table-thead">

      <tr class="angular-table-tr">

        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.category.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.category.label[selectedLanguage]"></span>
          <fb-tooltip *ngIf="confChange.category.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.category.tooltipText"></fb-tooltip>
        </th>

        <th class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.subCategory.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.subCategory.label[selectedLanguage]"></span>
          <fb-tooltip *ngIf="confChange.subCategory.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.subCategory.tooltipText"></fb-tooltip>
        </th>

        <th *ngIf="showProvisionalAmount" class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.provisionalAmount.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.provisionalAmount.label[selectedLanguage]"></span>
          <fb-tooltip *ngIf="confChange.provisionalAmount.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.provisionalAmount.tooltipText"></fb-tooltip>
        </th>

        <th *ngIf="!confChange.actualAmount.actouelAmountNotShow" class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.actualAmount.isRequired" style="color: red">* </span>
          <span [innerHTML]="confChange.actualAmount.label[selectedLanguage]"></span>
          <fb-tooltip *ngIf="confChange.actualAmount.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.actualAmount.tooltipText"></fb-tooltip>
        </th>
        <!--actualAmountTotal-->
        <th *ngIf="confChange.actualAmount.totalAmountNotShow" class="angular-table-th" style="text-align: center;border-bottom: 1px solid rgba(0,0,0,.12)">
          <span *ngIf="confChange.actualAmount.isRequired" style="color: red">* </span>
          <span >Montant prévisionnel total</span>
          <span  style="padding-top: 10px;">
             <mat-icon (click)="openDialog(getDialogTextValue)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
          <fb-tooltip *ngIf="confChange.actualAmount.hasTooltip == true" [lang]="selectedLanguage"
                      [tooltipText]="confChange.actualAmount.tooltipText"></fb-tooltip>
        </th>
        <!--actualAmountTotal-->
        <!-- <th class="angular-table-th" style="text-align: center;">

         </th>

         <th class="angular-table-th" style="text-align: center;">
           {{confChange.deleteLabel[selectedLanguage]}}
         </th>-->
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let row of bphChange.rows; let i = index">
        <tr *ngIf="!row.isTotal && !row.isEligibleExpensesSubTotalRow" class="angular-table-tr"
            style="border-bottom: 1px solid rgba(0,0,0,.12);">
          <td *ngIf="!row.category.hideCell"
              [attr.rowspan]="row.category.rowspan"
              [style.border-right]="(row.category.rowspan && row.category.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
              class="angular-table-td">
            <span>{{getCategoryIndex(row.category.value)}}
              - {{(row.category.label ? row.category.label[selectedLanguage] : '') | uppercase}}</span>
            <span
              *ngIf="getDialogText(row.category.value + '_')"
              style="padding-top: 10px;">
          <button (click)="openDialog(getDialogText(row.category.value + '_'))" mat-icon-button
                  style="margin-left: -8px;margin-bottom: -10px;"
                  type="button">
         <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; ">help_outline</mat-icon></button>
          </span>
            <div *ngIf="row.category.error && row.category.error[selectedLanguage]"
                 [ngClass]="row.category.error && row.category.error[selectedLanguage] ? 'hasError' : 'noError'">
              <span class="formFieldError">{{row.category.error[selectedLanguage]}}</span>
            </div>
          </td>

          <td class="angular-table-td">
            <span>{{concatBudgetItemSubCategory(row.subCategory)}}</span>
            <span
              *ngIf="getDialogText(row.category.value + '_'+ row.subCategory[row.subCategory.length -1].value)"
              style="padding-top: 10px;">
          <button
            (click)="openDialog(getDialogText(row.category.value + '_'+ row.subCategory[row.subCategory.length -1].value))"
            mat-icon-button
            style="margin-left: -8px;margin-bottom: -10px;"
            type="button">
         <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; ">help_outline</mat-icon></button>
          </span>
            <ng-container *ngIf="isIncludeOther(row.subCategory)">
              <br>
              <div *eCaseVar="getLevel(row.subCategory) as level">
                <label
                  [for]="'subCategoryOtherValue' + i + '_' + level">{{confChange.subCategory.otherValuelabel[selectedLanguage]}}</label>
                <mat-form-field appearance="outline">
                  <input
                    [(ngModel)]="row['subCategory'][level].otherValue"
                    [id]="'subCategoryOtherValue' + i + '_' + level"
                    matInput maxlength="100">
                </mat-form-field>
                <p class="counter-style">
                  {{getTextLength(row['subCategory'][level].otherValue)}}
                  / 100
                  {{'ecase.common.characters' | translate}}</p>
              </div>

            </ng-container>
            <div *ngIf="row.subCategory.error && row.subCategory.error[selectedLanguage]"
                 [ngClass]="row.subCategory.error && row.subCategory.error[selectedLanguage] ? 'hasError' : 'noError'">
              <span class="formFieldError">{{row.subCategory.error[selectedLanguage]}}</span>
            </div>
          </td>

          <td *ngIf="showProvisionalAmount"  class="angular-table-td td-padding" style="padding-right: 18px !important;">

            <div>
              <!--  <label><span style="color: red">*</span> {{confChange.provisionalAmount.label[selectedLanguage]}}</label>-->
              <mat-form-field appearance="outline">
                <input (keyup)="preventMaxlengthViolation(row['provisionalAmount'].value, 'provisionalAmount', 9, i)"
                       [(ngModel)]="row['provisionalAmount'].value"
                       id="provisionalAmount" matInput
                       maxlength="9" style="text-align: right"
                       [disabled]="confChange.provisionalAmount.isReadOnlyField"
                       type="text">
                <span matSuffix>{{confChange.provisionalAmount.currency}}</span>
              </mat-form-field>
              <p class="counter-style">
                {{getTextLength(row['provisionalAmount'].value)}} / 9
                {{'ecase.common.characters' | translate}}</p>
            </div>

            <!-- <span>{{convertNumberToNumericFormat(confChange.provisionalAmount.currency, row.provisionalAmount.value)}}</span>-->
            <div *ngIf="row.provisionalAmount.error && row.provisionalAmount.error[selectedLanguage]"
                 [ngClass]="row.provisionalAmount.error && row.provisionalAmount.error[selectedLanguage] ? 'hasError' : 'noError'">
              <span class="formFieldError">{{row.provisionalAmount.error[selectedLanguage]}}</span>
            </div>
          </td>

          <td *ngIf="!confChange.actualAmount.actouelAmountNotShow" class="angular-table-td td-padding" style="padding-right: 15px !important;">
            <div>
              <!-- <label><span style="color: red">*</span> {{confChange.actualAmount.label[selectedLanguage]}}</label>-->
              <mat-form-field appearance="outline">
                <input (keyup)="preventMaxlengthViolation(row['actualAmount'].value, 'actualAmount', 9, i)"
                       [(ngModel)]="row['actualAmount'].value"
                       id="actualAmount" matInput
                       maxlength="9" style="text-align: right"
                       type="text">
                <span matSuffix>{{confChange.actualAmount.currency}}</span>
              </mat-form-field>
              <p class="counter-style">
                {{getTextLength(row['actualAmount'].value)}} / 9
                {{'ecase.common.characters' | translate}}</p>
            </div>

            <!--<span>{{convertNumberToNumericFormat(confChange.actualAmount.currency, row.actualAmount.value)}}</span>-->
            <div *ngIf="row.actualAmount.error && row.actualAmount.error[selectedLanguage]"
                 [ngClass]="row.actualAmount.error && row.actualAmount.error[selectedLanguage] ? 'hasError' : 'noError'">
              <span class="formFieldError">{{row.actualAmount.error[selectedLanguage]}}</span>
            </div>
          </td>

          <!--  actualAmountTotal-->

          <td *ngIf="confChange.actualAmount.totalAmountNotShow" class="angular-table-td td-padding" style="padding-right: 15px !important;">
            <div>
              <!-- <label><span style="color: red">*</span> {{confChange.actualAmount.label[selectedLanguage]}}</label>-->
              <mat-form-field appearance="outline">
                <input disabled
                       [(ngModel)]="row['actualAmounttotal'].value"
                       id="actualAmounttotal" matInput
                       maxlength="9" style="text-align: right"
                       type="number">
                <span matSuffix>{{confChange.actualAmount.currency}}</span>
              </mat-form-field>
              <p class="counter-style">
                {{getTextLength(row['actualAmounttotal'].value)}} / 9
                {{'ecase.common.characters' | translate}}</p>
            </div>

            <!--<span>{{convertNumberToNumericFormat(confChange.actualAmount.currency, row.actualAmount.value)}}</span>-->
<!--            <div *ngIf="row.actualAmount.error && row.actualAmount.error[selectedLanguage]"-->
<!--                 [ngClass]="row.actualAmount.error && row.actualAmount.error[selectedLanguage] ? 'hasError' : 'noError'">-->
<!--              <span class="formFieldError">{{row.actualAmount.error[selectedLanguage]}}</span>-->
<!--            </div>-->
          </td>
          <!--actualAmountTotal-->
          <!--  <td  class="angular-table-td" style="text-align: center;">
              <button (click)="editRow(bphChange,confChange,i)" mat-icon-button>
                <mat-icon>create</mat-icon>
              </button>
            </td>
            <td  class="angular-table-td" style="text-align: center;">
              <button (click)="deleteRow(bphChange,confChange,i)" mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </td>-->
        </tr>
        <tr
          *ngIf="row.isTotal && !row.isEligibleExpensesSubTotalRow &&
          (bphChange.subTotalTypeOneEnabled || bphChange.rows[i - 1]?.category?.value === 2032701)"
          class="angular-table-tr boldfont" style="border-bottom: 1px solid rgba(0,0,0,.12);">

          <td class="angular-table-td">
            <span>{{confChange.subTotalLabel[selectedLanguage]}}</span>
          </td>

          <td class="angular-table-td">
          </td>

          <td *ngIf="showProvisionalAmount" class="angular-table-td" style="text-align: right !important;"
              [style.padding-right]="(confChange.actualAmount.actouelAmountNotShow) ? '18px !important' : ''">
            <span>
              {{getFormattedTotal(bphChange.rows, 'provisionalAmount', i, row.column, row, confChange.provisionalAmount.currency)}}
            </span>
          </td>

          <td *ngIf="!confChange.actualAmount.actouelAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getFormattedTotal(bphChange.rows, 'actualAmount', i, row.column, row, confChange.actualAmount.currency)}}
            </span>
          </td>
          <!--actualAmountTotal-->
          <td *ngIf="confChange.actualAmount.totalAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getFormattedTotal(bphChange.rows, 'actualAmounttotal', i, row.column, row, confChange.actualAmount.currency)}}
            </span>
          </td>
          <!-- actualAmountTotal-->
          <!--  <td class="angular-table-td">
            </td>

            <td class="angular-table-td">
            </td>-->

        </tr>
        <tr *ngIf="row.isEligibleExpensesSubTotalRow && bphChange.subTotalTypeOneEnabled"
            class="angular-table-tr" style="border-bottom: 1px solid rgba(0,0,0,.12);">

          <td class="angular-table-td" colspan="2" style="text-align: left !important;padding-left: 35px">
            <span *ngIf="row.categoryId !== NOT_ELIGIBLE_EXPENSES_BUDGET_ITEM" style="font-weight: bold">{{confChange.subTotalTypeOneLabel[selectedLanguage]}}</span>
            <span *ngIf="row.categoryId === NOT_ELIGIBLE_EXPENSES_BUDGET_ITEM" style="font-weight: bold">{{confChange.subTotalTypeOneLabelNonEligible[selectedLanguage]}}</span>
            <span *ngIf="confChange.subTotalTypeOneTooltipLabel" style="padding-top: 10px;">
          <button (click)="openDialog(confChange.subTotalTypeOneTooltipLabel)" mat-icon-button
                  style="margin-left: -8px;margin-bottom: -10px;"
                  type="button">
         <mat-icon style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important; ">help_outline</mat-icon></button>
          </span>
          </td>

          <td *ngIf="showProvisionalAmount" class="angular-table-td" style="text-align: right !important;"
              [style.padding-right]="(confChange.actualAmount.actouelAmountNotShow) ? '18px !important' : ''">
            <span style="font-weight: bold">
              {{getEligibleExpenseTotal(i, 'provisionalAmount', row.column, confChange.provisionalAmount.currency)}}
            </span>
          </td>

          <td *ngIf="!confChange.actualAmount.actouelAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span style="font-weight: bold">
              {{getEligibleExpenseTotal(i, 'actualAmount', row.column, confChange.provisionalAmount.currency)}}
            </span>
          </td>
          <!--  actualAmountTotal-->
          <td *ngIf="confChange.actualAmount.totalAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span style="font-weight: bold">
              {{getEligibleExpenseTotal(i, 'actualAmounttotal', row.column, confChange.provisionalAmount.currency)}}
            </span>
          </td>
          <!-- actualAmountTotal-->

        </tr>

      </ng-container>

      <tr *ngIf="confChange.hasGlobalTotal" class="active angular-table-tr boldfont">

        <td class="angular-table-td">
          <span>{{confChange.totalLabel[selectedLanguage]}}</span>
        </td>

        <td class="angular-table-td">
        </td>

        <td *ngIf="showProvisionalAmount" class="angular-table-td" style="text-align: right !important;"
            [style.padding-right]="(confChange.actualAmount.actouelAmountNotShow) ? '18px !important' : ''">
            <span>
              {{getGlobalTotal(bphChange.rows, 'provisionalAmount', confChange.provisionalAmount.currency, true)}}
            </span>
        </td>

        <td *ngIf="!confChange.actualAmount.actouelAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getGlobalTotal(bphChange.rows, 'actualAmount', confChange.actualAmount.currency, true)}}
            </span>
        </td>
        <!-- actualAmountTotal-->

        <td *ngIf="confChange.actualAmount.totalAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getGlobalTotal(bphChange.rows, 'actualAmounttotal', confChange.actualAmount.currency, true)}}
            </span>
        </td>
        <!-- actualAmountTotal-->
      </tr>

      <tr *ngIf="bphChange.subTotalTypeOneEnabled && isEligibleExpensesSubTotalRowRequired" class="active angular-table-tr boldfont">

        <td class="angular-table-td">
          <span>{{confChange.extraGlobalTotalLabel[selectedLanguage]}}</span>
        </td>

        <td class="angular-table-td">
        </td>

        <td *ngIf="showProvisionalAmount" class="angular-table-td" style="text-align: right !important;"
            [style.padding-right]="(confChange.actualAmount.actouelAmountNotShow) ? '18px !important' : ''">
            <span>
              {{getGlobalTotal(bphChange.rows, 'provisionalAmount', confChange.provisionalAmount.currency)}}
            </span>
        </td>

        <td *ngIf="!confChange.actualAmount.actouelAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getGlobalTotal(bphChange.rows, 'actualAmount', confChange.actualAmount.currency)}}
            </span>
        </td>
        <!-- actualAmountTotal-->

        <td *ngIf="confChange.actualAmount.totalAmountNotShow" class="angular-table-td" style="text-align: right !important;padding-right: 18px !important;">
            <span>
              {{getGlobalTotal(bphChange.rows, 'actualAmounttotal', confChange.actualAmount.currency)}}
            </span>
        </td>
        <!--actualAmountTotal-->
      </tr>


      </tbody>
    </table>
  </ng-container>

  <div fxLayout="column" style="align-items: flex-start">
    <!-- <button
       (click)="addRow(bphChange,confChange)"
       [style.margin-bottom]="bphChange.error[selectedLanguage] ? '5px' : 0"
       color="accent"
       mat-raised-button style="width:max-content">{{confChange.addRowlabel[selectedLanguage]}}
     </button>-->
    <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>


<div *ngIf="!bphChange.show && confChange.showDetails && showDetails" class="fb_table_dertails">

  <div>
    <span style="color: red">*</span> {{confChange.category.label[selectedLanguage]}}
    <mat-form-field appearance="outline">
      <mat-select (ngModelChange)="onCategoryChange($event, false)"
                  [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['category'].value">
        <mat-option *ngFor="let budgetItem of topLevelBudgetItems" [value]="budgetItem.value">
          {{budgetItem.label[selectedLanguage]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container
    *ngIf="dataObjectForRecursiveSubCategorySelect.subCategories && dataObjectForRecursiveSubCategorySelect.subCategories.length > 0">
    <ng-container
      *ngTemplateOutlet="RecursiveSubCategorySelect; context:{ 'dataObject': dataObjectForRecursiveSubCategorySelect}"></ng-container>
  </ng-container>


  <ng-template #RecursiveSubCategorySelect let-dataObject="dataObject">
    <div *ngIf="(bphChange.rows[selectedTableRowIndex])['subCategory'][dataObject.level]">
      <span style="color: red">*</span> {{confChange.subCategory.label[selectedLanguage]}}
      <mat-form-field appearance="outline">
        <mat-select (ngModelChange)="onSubCategoryChange($event, dataObject)"
                    [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['subCategory'][dataObject.level].value">
          <mat-option *ngFor="let subCategory of dataObject.subCategories" [value]="subCategory.value">
            {{subCategory.label[selectedLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="(bphChange.rows[selectedTableRowIndex])['subCategory'][dataObject.level].value >= 999999999">
        <br>
        <div [style.width]="getWidthFromPlatform()">
          <label><span style="color: red">*</span> {{confChange.subCategory.otherValuelabel[selectedLanguage]}}</label>
          <mat-form-field appearance="outline">
            <input [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['subCategory'][dataObject.level].otherValue"
                   [id]="'subCategoryOtherValue' + dataObject.level"
                   matInput maxlength="100">
          </mat-form-field>
          <p class="counter-style">
            {{getTextLength((bphChange.rows[selectedTableRowIndex])['subCategory'][dataObject.level].otherValue)}} / 100
            {{'ecase.common.characters' | translate}}</p>
        </div>

      </ng-container>
    </div>
    <ng-container
      *ngIf="dataObject.dataObject && dataObject.dataObject.subCategories && dataObject.dataObject.subCategories.length > 0">
      <ng-container
        *ngTemplateOutlet="RecursiveSubCategorySelect; context:{ 'dataObject': dataObject.dataObject}"></ng-container>
    </ng-container>
  </ng-template>

  <div *ngIf="!confChange.actualAmount.actouelAmountNotShow" [style.width]="getWidthFromPlatform()">
    <label><span style="color: red">*</span> {{confChange.actualAmount.label[selectedLanguage]}}</label>
    <mat-form-field appearance="outline">
      <input
        (keyup)="preventMaxlengthViolation((bphChange.rows[selectedTableRowIndex])['actualAmount'].value, 'actualAmount', 9, selectedTableRowIndex)"
        [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['actualAmount'].value"
        id="actualAmount" matInput
        maxlength="9" style="text-align:right"
        type="number">
      <span matSuffix>{{confChange.actualAmount.currency}}</span>
    </mat-form-field>
    <p class="counter-style">
      {{getTextLength((bphChange.rows[selectedTableRowIndex])['actualAmount'].value)}} / 9
      {{'ecase.common.characters' | translate}}</p>
  </div>

  <div [style.width]="getWidthFromPlatform()">
    <label><span style="color: red">*</span> {{confChange.provisionalAmount.label[selectedLanguage]}}</label>
    <mat-form-field appearance="outline">
      <input
        (keyup)="preventMaxlengthViolation((bphChange.rows[selectedTableRowIndex])['provisionalAmount'].value, 'provisionalAmount', 9, selectedTableRowIndex)"
        [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['provisionalAmount'].value"
        id="provisionalAmount" matInput
        maxlength="9" style="text-align:right"
        type="number">
      <span matSuffix>{{confChange.provisionalAmount.currency}}</span>
    </mat-form-field>
    <p class="counter-style">
      {{getTextLength((bphChange.rows[selectedTableRowIndex])['provisionalAmount'].value)}} / 9
      {{'ecase.common.characters' | translate}}</p>
  </div>
  <!--==================TTTTT-->
  <div *ngIf="!confChange.actualAmount.actouelAmountNotShow" [style.width]="getWidthFromPlatform()">
    <label><span style="color: red">*</span> {{confChange.actualAmount.label[selectedLanguage]}}Total</label>
    <mat-form-field appearance="outline">
      <input
        [(ngModel)]="(bphChange.rows[selectedTableRowIndex])['actualAmount'].value"
        id="actualAmountTotal" matInput
        maxlength="9" style="text-align:right"
        type="number">
      <span matSuffix>{{confChange.actualAmount.currency}}</span>
    </mat-form-field>
    <p class="counter-style">
      {{getTextLength((bphChange.rows[selectedTableRowIndex])['actualAmount'].value)}} / 9
      {{'ecase.common.characters' | translate}}</p>
  </div>
  <!--==================-->

  <button (click)="closeDetails(confChange,bphChange)" mat-raised-button
          style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%">
    <span>{{"ecase.common.save" | translate}}</span>
  </button>
  <button (click)="cancel(confChange,bphChange)" color="accent" mat-raised-button>
    <span>{{"ecase.common.cancel" | translate}}</span>
  </button>
</div>
