<div *ngIf="!bphChange.hide && !confChange.showDetails && !bphChange.showDetails && !globalConf.showDetails"
     [ngClass]="bphChange.error[selectedLanguage] ? 'hasError' : 'noError'"
     class="{{bphChange.error_class}} hide_{{bphChange.show}}"
     style="margin-bottom: 1.34375em">
  <div class="formLabel">
    <ng-container *ngIf="confChange.condensedLabel && confChange.condensedLabel[selectedLanguage]">
      <span style="font-weight: bolder" [innerHTML]="confChange.condensedLabel[selectedLanguage]"></span>
      <br>
    </ng-container>
    <span *ngIf="confChange.isRequired" style="color: red">* </span>
    <span *ngIf="confChange.tableLabel" [innerHTML]="confChange.tableLabel[selectedLanguage]"></span>
    <span *ngIf="confChange.hasTooltip" style="padding-top: 10px;">
             <mat-icon matTooltip="{{confChange.tooltipText[selectedLanguage]}}"
                       matTooltipClass="tooltip">help_outline</mat-icon>
          </span>

    <span *ngIf="confChange.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(confChange)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
          </span>
  </div>
  <div *ngIf="confChange.showTotalRows && confChange.showTotalRowsVerticalAligment === 'top' || confChange.showTotalRowsVerticalAligment === 'both'"
       [style.float]="confChange.showTotalRowsHorizontalAlignment">
    <p style="font-weight: bolder; font-size: 12px;">{{confChange.totalRowsLabel[selectedLanguage]}} {{bphChange.rows.length}}</p>
  </div>
  <ng-container
    *ngIf="!confChange.hasShowNodata || (confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length > 0)">
    <div class="budget-table-responsive">
      <table [id]="selectedSectionId + '_' + confChange.name"
             class="angular-table {{bphChange.error_class}}" style="width: 100%;margin-bottom: 10px;margin-top: 5px">
        <thead class="angular-table-thead">
        <tr *ngIf="confChange.showSuperHeader" class="angular-table-tr">
          <ng-template [ngForOf]="confChange.headers" let-aBlock ngFor>
            <th [attr.colspan]="aBlock.colspan"
                [ngClass]="aBlock.align ? (aBlock.align.includes('left') ? 'align-start' : (aBlock.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
                [style.border-left]="(aBlock.colspan && aBlock.colspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
                [style.border-right]="(aBlock.colspan && aBlock.colspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
                [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
                class="angular-table-th">
              <span *ngIf="aBlock.isRequired" style="color: red">* </span>
              <span *ngIf="!aBlock.isTableColumnLabel" [innerHTML]="aBlock.label[selectedLanguage]"></span>
              <span *ngIf="aBlock.isTableColumnLabel" [innerHTML]="aBlock.tableColumnLabel[selectedLanguage]"></span>
              <span *ngIf="aBlock.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(aBlock)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
            </span>
              <span *ngIf="aBlock.isShowDateFormat" style="white-space: nowrap"><br>({{aBlock.dateFormatToBeShown[selectedLanguage]}}
                )</span>
            </th>
          </ng-template>
        </tr>
        <tr class="angular-table-tr">
          <th *ngIf="confChange.listName"
              [ngClass]="confChange.align ? (confChange.align.includes('left') ? 'align-start padding-left' : (confChange.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
              [style.min-width.px]="confChange.min_width ? confChange.min_width : ''"
              [style.width.%]="confChange.width_pct ? confChange.width_pct : ''"
              class="angular-table-th"
              style="min-width: 250px">{{confChange.listNameColumnHeaderLabel ? confChange.listNameColumnHeaderLabel[selectedLanguage] : confChange.listNameToShow}}</th>
          <!-- <th *ngIf="confChange.isRowsDraggable" class="angular-table-th"></th> -->
          <th *ngIf="!confChange.hideYearsExpenditure" class="angular-table-th"></th>
          <ng-template [ngForOf]="confChange.subBlocks" let-aBlock ngFor>
            <th *ngIf="!aBlock.hideCell" [ngClass]="aBlock.align ? (aBlock.align.includes('left') ? 'align-start' : (aBlock.align.includes('right') ? 'align-end' : 'align-center')) : 'align-center'"
                [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
                class="angular-table-th"
                style="border-bottom: 1px solid rgba(0,0,0,.12);min-width: 180px;">
              <div fxLayout="row" style="align-items: center;"
                   [style.justify-content]="aBlock.textHeadAlignment ? 'left' : 'center'">
                <span *ngIf="aBlock.isRequired" style="color: red">* </span>
                <span *ngIf="!aBlock.isTableColumnLabel" [innerHTML]="aBlock.label[selectedLanguage]"
                      [style.cursor]="aBlock.isSortable ? 'pointer' : ''"
                      [style.font-weight]="aBlock.sortActive ? 'bolder' : ''"
                      (click)="sortRows(aBlock)"></span>
                <span *ngIf="aBlock.isTableColumnLabel" [innerHTML]="aBlock.tableColumnLabel[selectedLanguage]"
                      [style.cursor]="aBlock.isSortable ? 'pointer' : ''"
                      [style.font-weight]="aBlock.sortActive ? 'bolder' : ''"
                      (click)="sortRows(aBlock)"></span>
                <span *ngIf="aBlock.isShowDateFormat" style="white-space: nowrap"><br>({{aBlock.dateFormatToBeShown[selectedLanguage]}}
                  )</span>
                <mat-icon *ngIf="aBlock.isSortable && aBlock.sortActive" style="cursor: pointer" (click)="sortRows(aBlock)">
                  {{aBlock.sortDirection === 'asc' ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                </mat-icon>
              </div>
              <fb-tooltip *ngIf="aBlock.hasTooltip == true && !aBlock.hideTooltipHeader" [lang]="selectedLanguage"
                          [tooltipText]="aBlock.tooltipText"></fb-tooltip>
              <span *ngIf="aBlock.hasDialog" style="padding-top: 10px;">
             <mat-icon (click)="openDialog(aBlock)"
                       style="cursor: pointer; vertical-align: middle !important; margin-top: -2px !important;">help_outline</mat-icon>
            </span>
            </th>
          </ng-template>
          <th *ngIf="confChange.readOnlyTable" class="angular-table-th"> {{confChange.editLabel ? confChange.editLabel[selectedLanguage] : '' }}</th>
          <th *ngIf="confChange.showDelete" class="angular-table-th"
              style="text-align: center;padding-right: 10px !important; border-bottom: 1px solid rgba(0, 0, 0, 0.12); min-width: 80px; padding-bottom: 8px !important;">
            {{confChange.deleteLabel[selectedLanguage]}}
          </th>
        </tr>
        </thead>

        <thead *ngIf="confChange.isColumnsFiltered" class="angular-table-thead">
        <tr class="angular-table-tr">
          <th *ngIf="confChange.listName"
              [style.min-width.px]="confChange.min_width ? confChange.min_width : ''"
              [style.width.%]="confChange.width_pct ? confChange.width_pct : ''"
              class="mat-table_filtered_header_cell"
              style="min-width: 250px"></th>

          <ng-container *ngFor="let aBlock of confChange.subBlocks; let subBlockIndex=index">
            <th *ngIf="!aBlock.hideCell" [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
                class="angular-table-th mat-table_filtered_header_cell">
              <span class='nodisplay'>.</span>
              <input *ngIf="showFilteredImage && !confChange.isRowsDraggable && subBlockIndex === 0" alt="clear" class="row_filter_icon"
                     disabled matRipple
                     src="../../../../assets/images/etc/icons8-conversion.svg" type="image">
              <input (click)="resetFilters()" *ngIf="!showFilteredImage && !confChange.isRowsDraggable && subBlockIndex === 0" alt="clear"
                     class="row_filter_icon" src="../../../../assets/images/etc/icons8-clear-filters.svg"
                     type="image">
              <input  *ngIf="isFilterFieldVisible(aBlock)" class="searchFields" type="text"
                      [(ngModel)]="aBlock.valueToFilter"
                      [attr.aria-label]="'ecase.common.search' | eCaseTranslate"
                      [placeholder]="'ecase.common.search' | eCaseTranslate"
                      (keyup)="applyFilter($event, aBlock)">
            </th>
          </ng-container>
          <th *ngIf="confChange.readOnlyTable" class="angular-table-th mat-table_filtered_header_cell"></th>
          <th *ngIf="confChange.showDelete" class="angular-table-th mat-table_filtered_header_cell">
          </th>
        </tr>
        </thead>
        <!-- TABLE BODY CONSTRUCTOR START -->
        <tbody *ngIf="!confChange.isRowsDraggable">
        <ng-container *ngFor="let row of bphChange.renderRows; let i = index">
          <tr [class]="row.isTotal ? 'active': ''" class="angular-table-tr" [style.display]="row.isHidden ? 'none' : ''"
              [style.background-color]="row.isActivateTableRowColor ? row.tableRowColor : ''"
              [style.font-weight]="row.isActivateTableRowFont ? row.tableRowFont : ''"
              style="border-bottom: 1px solid rgba(0,0,0,.12);">
            <ng-container
                    *ngTemplateOutlet="commonTableBody; context: {templateContext : row , rowIndex : i}"></ng-container>
          </tr>
          <ng-container *ngIf="!confChange.hideYearsExpenditure">
            <ng-container *ngTemplateOutlet="yearsRow; context: {templateContext : row , rowIndex : i}"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="otherTableRows;"></ng-container>
        </tbody>
        <!-- TABLE BODY CONSTRUCTOR END -->
      </table>
    </div>

    <div *ngIf="confChange.showTotalRows && confChange.showTotalRowsVerticalAligment === 'top' || confChange.showTotalRowsVerticalAligment === 'both'"
         [style.float]="confChange.showTotalRowsHorizontalAlignment">
      <p style="font-weight: bolder; font-size: 12px;">{{confChange.totalRowsLabel[selectedLanguage]}} {{bphChange.rows.length}}</p>
    </div>
   <ng-container *ngIf="confChange.isTablePaginated">
     <mat-paginator [length]="this.bphChange.rows.length"
                    [pageSize]="pageSize"
                    [pageIndex]="pageIndex"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="updatePagination($event)"
                    aria-label="Select page">
     </mat-paginator>
   </ng-container>
  </ng-container>
  <ng-container *ngIf="confChange.hasShowNodata && !confChange.showAddRow && bphChange.rows.length === 0">
    <span>{{confChange.showNodatatext[selectedLanguage]}}</span>
  </ng-container>
  <div fxLayout="column" style="align-items: flex-start">
    <button
      (click)="addRow(bphChange,confChange)"
      *ngIf="confChange.showAddRow && (!confChange.isLimitOnRowEnabled || (confChange.isLimitOnRowEnabled && bphChange.rows.length < confChange.rowLimit))"
      [disabled]="confChange.isReadOnlyField || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
      [style.margin-bottom]="bphChange.error[selectedLanguage] ? '5px' : 0"
      color="accent"
      mat-raised-button style="width:max-content">{{confChange.label[selectedLanguage]}}
    </button>
    <span *ngIf="bphChange.error[selectedLanguage]" class="tableSpanError">{{bphChange.error[selectedLanguage]}}</span>
  </div>
</div>

<!-- SUMMARY TABLE START -->
<div *ngIf="confChange.showSummaryTable && !bphChange.hide && !confChange.showDetails && !bphChange.showDetails && !globalConf.showDetails" class="readOnlyTable" style="margin-top: 15px;">
  <table class="angular-table">

    <!-- SUMMARY TABLE HEADER START -->
    <tr class="angular-table-tr header-table">
      <th colspan="8">
        {{confChange.summaryTabelLabel[selectedLanguage]}}
      </th>
    </tr>
    <!-- SUMMARY TABLE HEADER END -->

    <!-- SUMMARY TABLE HEADERS START -->
    <tr class="angular-table-tr" style="background-color: #f0f0f0">
      <th class="angular-table-th">{{confChange.categoryLabel[selectedLanguage]}}</th>

      <th *ngIf="confChange.showSubCategoryInSummary" class="angular-table-th">{{confChange.subCategoryLabel[selectedLanguage]}}</th>

      <ng-container *ngIf="!confChange.hideYearsExpenditure">
        <ng-container *ngFor="let row of bphChange.yearRows | keyvalue">
          <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}} {{+row.key + 1}}</th>
        </ng-container>
      </ng-container>

      <th class="angular-table-th">{{confChange.subtotalLabel[selectedLanguage]}}</th>
    </tr>
    <!-- SUMMARY TABLE HEADERS END -->

    <ng-container *ngFor="let row of bphChange?.summaryTable?.rows">

      <tr class="angular-table-tr subTotalStyles">
        <td class="angular-table-td"><span>{{row.categoryLabel[selectedLanguage]}}</span></td>

        <ng-container *ngIf="confChange.showSubCategoryInSummary">
          <td class="angular-table-td"><span>{{row.subCategoryLabel[selectedLanguage]}}</span></td>
        </ng-container>

        <ng-container *ngIf="!confChange.hideYearsExpenditure">
          <ng-container *ngFor="let yearRow of row?.yearRows;">
            <td class="angular-table-td">{{convertNumberToCurrencyFormat(yearRow.value, confChange.currency)}}</td>
          </ng-container>
        </ng-container>


        <td class="angular-table-td">{{convertNumberToCurrencyFormat(row?.rowTotal.value, confChange.currency)}}</td>

      </tr>
    </ng-container>

    <tr class="angular-table-tr totalStyles" style="background: #ccc !important;">
      <td class="angular-table-td">{{confChange.grandTotalLabel[selectedLanguage]}}</td>
      <ng-container *ngIf="confChange.showSubCategoryInSummary"><td class="angular-table-td"></td></ng-container>
      <ng-container *ngIf="!confChange.hideYearsExpenditure">
        <ng-container *ngFor="let yearTotal of bphChange.summaryTable?.yearTotals;">
          <td class="angular-table-td">{{convertNumberToCurrencyFormat(yearTotal.value, confChange.currency)}}</td>
        </ng-container>
      </ng-container>
      <td class="angular-table-td">{{convertNumberToCurrencyFormat(bphChange.summaryTable?.grandSubTotal.value, confChange.currency)}}</td>
    </tr>

  </table>
</div>
<!-- SUMMARY TABLE END -->

<!-- BUDGET DETAIL ROW VIEW START -->
<div
  *ngIf="!bphChange.show && confChange.readOnlyTable && confChange.showDetails && bphChange.showDetails && bphChange.rows[selectedTableRowIndex]"
  class="fb_table_dertails">
  <!-- _BPH_TE_SUBCONTENT_S --> <!-- _BPH_TE_SUBCONTENT_E -->

  <ng-container *ngFor="let subBlock of confChange.subBlocks">
      <ng-container *ngIf="!subBlock.hideCellInDetailView" [ngSwitch]="subBlock.templateName">
        <ng-container *ngSwitchCase="'statictext'">
          <ng-container
            *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <ng-container
            *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Radiobutton'">
          <ng-container
            *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <ng-container
            *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Container'">
          <ng-container
            *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Table'">
          <ng-container
            *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SubmitButton'">
          <ng-container
            *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'upload'">
          <ng-container
            *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext2 , rowIndex : selectedTableRowIndex, blockIndex : (blockIndex + ((selectedTableRowIndex + 1) / 100))}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <ng-container
            *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Checkbox'">
          <ng-container
            *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'datepicker'">
          <ng-container
            *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'AutoComplete'">
          <ng-container
            *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'richtext'">
          <ng-container
            *ngTemplateOutlet="richtext; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <ng-container
            *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext2 , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>
  </ng-container>

  <table *ngIf="!confChange.hideYearsExpenditure" id="budget_t1_table" class="angular-table showSecondTable" style="margin-bottom: 1.34375em;">
    <thead class="angular-table-thead">
    <tr class="angular-table-tr">
      <th class="angular-table-th">{{confChange.yearLabel[selectedLanguage]}}</th>
      <th class="angular-table-th">{{confChange.subtotalDetailLabel ? confChange.subtotalDetailLabel[selectedLanguage] : ''}}</th>
    </tr>
    </thead>

    <ng-container *ngFor="let expandableRows of tempRow.expandRows">
      <tr class="angular-table-tr">
        <td class="angular-table-td">{{expandableRows.label[selectedLanguage]}}</td>
        <td class="angular-table-td" style="padding: 2px 18px;">
          <div class="subtotal-field"
               [ngClass]="expandableRows?.subTotal.error && expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage] ? 'hasError' : 'noError'">
            <mat-form-field appearance="outline"
                            [ngClass]="expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage] ? 'ng-invalid mat-form-field-invalid' : ''">
              <input  id="subtotal" matInput type="number" min="0" max="999999999"
                      [disabled]="confChange.isReadOnlyField"
                      (input)="expandableRows.subTotal.value < 0 ? expandableRows.subTotal.value = 0 : onSubTotalInput(bphChange.selectedTable, tempRow, expandableRows, $event);"
                      [(ngModel)]="expandableRows.subTotal.value" maxlength="9" >
            </mat-form-field>
            <p class="counter-style" style="margin-top: 0px; text-align: right;">
              {{getTextLength2(expandableRows.subTotal.value)}} / 9</p>
            <div *ngIf="expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage]"
                 class="formFieldError">{{expandableRows?.subTotal.error[selectedLanguage]}}
            </div>
          </div>
        </td>

      </tr>
    </ng-container>
    <tr class="angular-table-tr">
      <td class="angular-table-td"><span>{{confChange.subtotalLabel[selectedLanguage]}}</span></td>
      <td class="angular-table-td">
        <span>{{convertNumberToCurrencyFormat(tempRow.total.value, confChange.currency)}}</span>
      </td>
    </tr>

  </table>

  <button (click)="closeDetails(confChange,bphChange)"
          mat-raised-button
          style=" background-color: #4FC3F7; color: #FFFFFF; margin-right: 1%">
    <span>{{getTranslatedLabel("ecase.common.save")}}</span>
  </button>
  <button (click)="cancel(confChange,bphChange)"
          color="accent" mat-raised-button><span>{{getTranslatedLabel("ecase.common.cancel")}}</span>
  </button>
</div>
<!-- BUDGET DETAIL ROW VIEW END -->

<ng-template #otherTableRows>
  <tr *ngIf="confChange.hasGlobalTotal" class="active angular-table-tr">

    <td *ngIf="confChange.listName" class="angular-table-td" style="font-weight: bold !important">
      {{confChange.listNameGlobalTotalLabel ? confChange.listNameGlobalTotalLabel[selectedLanguage] : ''}}
    </td>
    <!--<td *ngIf="confChange.isRowsDraggable" class="angular-table-td" style="font-weight: bold !important"></td> -->
    <td *ngFor="let aBlock of filterHideCellSubBlocks(confChange.subBlocks); let i = index" [ngClass]="getAlignClass()"
        [style.width.%]="(confChange.isWidthSpecifiedForSubBlocks && aBlock.columnWidthPercentage) ? aBlock.columnWidthPercentage : ''"
        class="angular-table-td"
        style="white-space:nowrap; font-weight: bold !important ;"
    [style.padding-right] ="aBlock.isTotalText ? '10%'  : ''">
      <ng-template [ngIf]="aBlock.hasGlobalTotal && !aBlock.isTotalText">
       {{getTranslatedLabel("ecase.common.globaltotaltext")}} {{getGlobalTotal(bphChange.rows, aBlock.name, i, bphChange.rows[bphChange.rows.length - 1], aBlock.currency, aBlock.globalTotalText, aBlock)}}
      </ng-template>
      <ng-template [ngIf]="aBlock.hasGlobalTotal && aBlock.isTotalText" style="text-align: left !important;">
        {{aBlock.globalTotalText[selectedLanguage]}}
      </ng-template>
    </td>
    <td *ngIf="confChange.readOnlyTable" class="angular-table-td">

    </td>
    <td *ngIf="confChange.showDelete" class="angular-table-td">

    </td>
  </tr>
  <tr *ngIf="confChange.showTableRowCountRow" class="active angular-table-tr">
    <td class="angular-table-td"></td>
    <td class="angular-table-td">
      <span *ngIf="confChange.tableRowCountRowLabel">{{confChange.tableRowCountRowLabel[selectedLanguage]}}</span>
    </td>
    <td class="angular-table-td">
      <span>{{bphChange.rows.length}}</span>
    </td>
    <ng-container *ngIf="confChange.subBlocks.length > 2">
      <td
        *ngFor="let aBlock of createArrayOfLength(filterHideCellSubBlocks(confChange.subBlocks).length - 2); let i = index"
        class="angular-table-td">

      </td>
    </ng-container>
    <td *ngIf="confChange.readOnlyTable" class="angular-table-td">

    </td>
    <td *ngIf="confChange.showDelete" class="angular-table-td">

    </td>
  </tr>
</ng-template>

<ng-template #commonTableRows let-i="rowIndex" let-row="templateContext">

  <!-- Expand year rows cell -->
  <td class="angular-table-td" *ngIf="!confChange.hideYearsExpenditure">
    <mat-icon style="cursor: pointer; margin-right: 5px;" (click)="showTableRow(row, i)">
      {{row.isShowTableRow ? 'remove_circle_outline' : 'add_circle_outline'}}
    </mat-icon>
  </td>
  <!-- End Expand year rows cell -->

  <!-- All the row blocks from formbuilder -->
  <ng-container>
    <ng-container *ngFor="let subBlock of confChange.subBlocks">
      <ng-container *ngIf="!subBlock.hideCell" [ngSwitch]="subBlock.templateName">
        <ng-container *ngSwitchCase="'statictext'">
          <ng-container
            *ngTemplateOutlet="statictext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <ng-container
            *ngTemplateOutlet="textarea; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Radiobutton'">
          <ng-container
            *ngTemplateOutlet="Radiobutton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <ng-container
            *ngTemplateOutlet="select; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Container'">
          <ng-container
            *ngTemplateOutlet="Container; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Table'">
          <ng-container
            *ngTemplateOutlet="Table; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SubmitButton'">
          <ng-container
            *ngTemplateOutlet="SubmitButton; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'upload'">
          <ng-container
            *ngTemplateOutlet="upload; context: {templateContext : subBlock.templateContext , rowIndex : i, blockIndex : (blockIndex + ((i + 1) / 100))}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <ng-container
            *ngTemplateOutlet="text; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Checkbox'">
          <ng-container
            *ngTemplateOutlet="Checkbox; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'datepicker'">
          <ng-container
            *ngTemplateOutlet="datepicker; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'AutoComplete'">
          <ng-container
            *ngTemplateOutlet="AutoComplete; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'button'">
          <ng-container
            *ngTemplateOutlet="button; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'richtext'">
          <ng-container
            *ngTemplateOutlet="richtext; context: {templateContext : subBlock.templateContext , rowIndex : i}"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <!-- End All the row blocks from formbuilder -->

  <!-- Edit button cell in table rows view -->
  <td *ngIf="confChange.readOnlyTable && !confChange.hideEditButton" class="angular-table-td"
      style="text-align: center;">
    <button (click)="editRow(bphChange,confChange,i)"
            *ngIf="!row.rowEditButtonHide"
            [disabled]="confChange.isReadOnlyField || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
            mat-icon-button>
      <mat-icon>create</mat-icon>
    </button>
  </td>
  <!-- End Edit button cell in table rows view -->

  <!-- Delete button cell in table rows view -->
  <td *ngIf="confChange.showDelete" class="angular-table-td"
      style="text-align: center;">
    <button (click)="deleteRow(bphChange,confChange,i)"
            *ngIf="!row.rowDeleteButtonHide"
            [disabled]="confChange.isReadOnlyField || row.isDeleteDisabled || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')"
            [style.opacity]="(confChange.isReadOnlyField || row.isDeleteDisabled || (bphChange.globalReadOnlyMode && bphChange.globalReadOnlyMode === 'true')) ? 0.5 : 1"
            mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </td>
  <!-- End Delete button cell in table rows view -->
</ng-template>

<!-- TOTAL ROW START -->
<ng-template #totalTableRows let-i="rowIndex" let-row="templateContext">
  <ng-container *ngFor="let column of this.listOfGreyColumns;">
    <!-- GREY CELL START -->
    <ng-container *ngIf="!column.name && !column.label">
      <ng-container *ngTemplateOutlet="greyColumn;"></ng-container>
    </ng-container>
    <!-- GREY CELL END -->
    <!-- LABEL CELL START -->
    <ng-container *ngIf="column.label">
      <td class="angular-table-td" style="background-color: lightgrey;">
          <span style="padding-top: 13px;width: 100%;font-weight: bold !important;">{{row.label[selectedLanguage] + " :"}}</span>
      </td>
    </ng-container>
    <!-- LABEL CELL END -->

    <!-- GRAND TOTAL CELL START -->
    <ng-container *ngIf="column.name === 'total'">
      <td class="angular-table-td" style="font-weight: bold !important;background-color: lightgrey;">
        {{convertNumberToCurrencyFormat(row.value.value, confChange.currency)}}
      </td>
    </ng-container>

    <ng-container *ngIf="column.name && column.name !== 'total'">
      <td class="angular-table-td" style="font-weight: bold !important;background-color: lightgrey;">
        {{convertNumberToCurrencyFormat(row[column.name].value, confChange.currency)}}
      </td>
    </ng-container>
    <!-- GRAND TOTAL CELL END -->
  </ng-container>
</ng-template>
<!-- TOTAL ROW END -->

<!-- TABLE BODY START -->
<ng-template #commonTableBody let-i="rowIndex" let-row="templateContext">

  <!-- Common table row with data -->
  <ng-container *ngIf="row.uniqueRowIndex">
    <ng-container *ngTemplateOutlet="commonTableRows; context: {templateContext: row, rowIndex: i}" ></ng-container>
  </ng-container>
  <!-- End Common table row with data -->

  <!-- Total table row with data -->
  <ng-container *ngIf="row.isTotal">
    <ng-container *ngTemplateOutlet="totalTableRows; context: {templateContext: row, rowIndex: i}" ></ng-container>
  </ng-container>
  <!-- End Total table row with data -->

  <!-- Category and Subcategory total row with data -->
  <ng-container *ngIf="(row.isCategoryTotal && confChange.showCategoriesTotal) || (row.isSubCategoryTotal && confChange.showSubCategoriesTotal)">
    <ng-container *ngTemplateOutlet="subTotalRows; context: {templateContext: row, rowIndex: i}" ></ng-container>
  </ng-container>
  <!-- End Category and Subcategory row with data -->

</ng-template>
<!-- TABLE BODY END -->

<!-- YEARS ROW START -->
<ng-template #yearsRow let-i="rowIndex" let-row="templateContext">
  <ng-container *ngIf="row.isShowTableRow && !row.isTotal && !row.isCategoryTotal && !row.isSubCategoryTotal">
    <tr class="angular-table-tr" *ngFor="let expandableRows of row.expandRows">

      <ng-container *ngFor="let column of this.listOfGreyColumns;">

        <ng-container *ngIf="column.name !== 'total' && !column.label">
          <ng-container *ngTemplateOutlet="greyColumn;"></ng-container>
        </ng-container>
        <ng-container *ngIf="column.label">
          <td class="angular-table-td" style="background-color: lightgrey;">
            <span style="padding-top: 13px;width: 100%;">{{expandableRows.label[selectedLanguage] + " :"}}</span>
          </td>
        </ng-container>
        <ng-container *ngIf="column.name === 'total'">
          <td class="angular-table-td" style="background-color: lightgrey;">
            <div>
              <span>{{convertNumberToCurrencyFormat(expandableRows.subTotal.value, confChange.currency)}}</span>
              <div
                [ngClass]="expandableRows?.subTotal && expandableRows?.subTotal.error && expandableRows?.subTotal.error[selectedLanguage] ? 'hasError' : 'noError'">
                            <span *ngIf="expandableRows?.subTotal && expandableRows?.subTotal.error[selectedLanguage]"
                                  class="tableFormFieldError">{{expandableRows?.subTotal.error[selectedLanguage]}}
                            </span>
              </div>
            </div>
          </td>
        </ng-container>
      </ng-container>

    </tr>
  </ng-container>
</ng-template>
<!-- YEARS ROW END -->

<!-- CATEGORY AND SUBCATEGORY SUBTOTALS ROW START -->
<ng-template #subTotalRows let-i="rowIndex" let-row="templateContext">
  <ng-container *ngFor="let column of this.listOfGreyColumns;">

    <ng-container *ngIf="!column.name && !column.label">
      <ng-container *ngTemplateOutlet="greyColumn;"></ng-container>
    </ng-container>

    <ng-container *ngIf="column.label">
      <td class="angular-table-td" style="background-color: lightgrey;">
        <span style="padding-top: 13px;width: 100%;">{{row.label[selectedLanguage] + " :"}}</span>
      </td>
    </ng-container>

    <ng-container *ngIf="column.name === 'total'">
      <td class="angular-table-td" style="background-color: lightgrey;">
        <div>
          <span>{{convertNumberToCurrencyFormat(row.value.value, confChange.currency)}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="column.name && column.name !== 'total'">
      <td class="angular-table-td" style="background-color: lightgrey;">
        <div>
          <span>{{convertNumberToCurrencyFormat(row[column.name].value, confChange.currency)}}</span>
        </div>
      </td>
    </ng-container>


  </ng-container>
</ng-template>
<!-- CATEGORY AND SUBCATEGORY SUBTOTALS ROW END -->

<ng-template #SubmitButton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-submit-button
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj' [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    style='position: static;display: table-cell;vertical-align: middle;padding-right:20px;'></fb-submit-button>
</ng-template>
<ng-template #statictext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-static-text
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-static-text>
</ng-template>
<ng-template #textarea let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text-area
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-text-area>
</ng-template>
<ng-template #Radiobutton let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-radiobutton
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-radiobutton>
</ng-template>
<ng-template #select let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-select (emitOutputEvent)='(confChange.isEnableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
             *ngIf="!isValidTableReadonly"
             [bphChange]='((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name])'
             [confChange]='confChange'
             [globalConf]='globalConf'
             [globalPrj]='globalPrj'
             [isValidHtml]='isValidHtml'
             [isValidTableReadonly]='isValidTableReadonly'
             [isValidTable]='isValidTable'
             [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
             [selectedLanguage]='selectedLanguage'
             [selectedSectionId]='selectedSectionId'
             [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
             [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-select>


  <ng-container
    *eCaseVar="(((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]) ? ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]) : {}) as componentBphChange">
    <td *ngIf="isValidTableReadonly && !componentBphChange.hideCell && !confChange.hideCell"
        [attr.rowspan]="componentBphChange.rowspan"
        [ngClass]="(componentBphChange?.error && componentBphChange?.error[selectedLanguage]) ? 'hasError' : 'noError'"
        [style.border-right]="(componentBphChange.rowspan && componentBphChange.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"
        [style.text-align]=" confChange.align ? confChange.align +'!important' : 'center !important'"
        [style.padding-left]=" confChange.align ? '' : '10px'"
        [style.font-weightr]="confChange.align  ? 'bold' : ''"
        [style.width.%]="confChange.isWidthCustom ? confChange.customWidthInPercentage : (100 / widthDividerForDynamicWidth)"
        style="border:0;text-align: center;padding-right:10px ;border-bottom: 1px solid rgba(0, 0, 0, 0.12); min-width: 80px;">
      <div class="field {{componentBphChange.error_class}} hide_{{bphChange.show}}">
        {{getValueForFbSelectSubBlock(componentBphChange, selectedLanguage)}}
        <span *ngIf="confChange.showOtherValueInReadOnlyTable">
<br>{{getOtherValueForFbSelectSubBlock(componentBphChange)}}
</span>
        <span *ngIf="(componentBphChange?.error && componentBphChange?.error[selectedLanguage])"
              class="tableFormFieldError">{{componentBphChange.error[selectedLanguage]}}</span>
      </div>
    </td>
  </ng-container>

  <!--<ng-container-->
  <!--*eCaseVar="(((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) ? ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.rows[rowIndex])[confChange.name]) : {}) as componentBphChange">-->
  <!--<td *ngIf="isValidTableReadonly && !componentBphChange.hideCell && !confChange.hideCell"-->
  <!--[attr.rowspan]="componentBphChange.rowspan"-->
  <!--[ngClass]="(componentBphChange?.error && componentBphChange?.error[selectedLanguage]) ? 'hasError' : 'noError'"-->
  <!--[style.border-right]="(componentBphChange.rowspan && componentBphChange.rowspan > 1) ? '1px solid rgba(0, 0, 0, .12)' : ''"-->
  <!--[style.width.%]="confChange.isWidthCustom ? confChange.customWidthInPercentage : (100 / widthDividerForDynamicWidth)"-->
  <!--style="border:0;text-align: center;padding-left:10px;padding-right:10px">-->
  <!--<div class="field {{componentBphChange.error_class}} hide_{{bphChange.show}}">-->
  <!--{{getValueForFbSelectSubBlock(componentBphChange, selectedLanguage)}}-->
  <!--<span *ngIf="(componentBphChange?.error && componentBphChange?.error[selectedLanguage])"-->
  <!--class="tableFormFieldError">{{componentBphChange.error[selectedLanguage]}}</span>-->
  <!--</div>-->
  <!--</td>-->
  <!--</ng-container>-->
</ng-template>

<ng-template #text let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-text
    (keydown.enter)='handleEnterKey($event, confChange)'
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.background-color]="confChange.backgroundColor ? confChange.backgroundColor : ''"
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'"
    [style.background-color]="confChange.backgroundColor ? confChange.backgroundColor : ''">
  </fb-text>
</ng-template>
<ng-template #upload let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable" let-blockIndex="blockIndex"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-upload
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offlineModeEnabled'
    [selectedLanguage]='selectedLanguage'
    [blockIndex]='blockIndex'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-upload>
</ng-template>
<ng-template #button let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-button
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [sections]="sections"
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [offlineModeEnabled]='offlineModeEnabled'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-button>
</ng-template>
<ng-template #Checkbox let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-checkbox
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-checkbox>
</ng-template>
<ng-template #datepicker let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-datepicker
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-datepicker>
</ng-template>
<ng-template #AutoComplete let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-autoComplete
    (emitOutputEvent)='(confChange.enableOutputEvent) ? executeFunction(
                   confChange,
                   ((!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]),
                   ((!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex)) : doNothing()'
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-autoComplete>
</ng-template>
<ng-template #richtext let-confChange="templateContext.confChange"
             let-isValidHtml="templateContext.isValidHtml"
             let-isValidTable="templateContext.isValidTable"
             let-isValidTableReadonly="templateContext.isValidTableReadonly" let-rowIndex="rowIndex">
  <fb-rich-text-quill
    [bphChange]='(!rowIndex && rowIndex !== 0) ? (bphChange.rows[selectedTableRowIndex])[confChange.name] : (bphChange.renderRows[rowIndex])[confChange.name]'
    [confChange]='confChange'
    [globalConf]='globalConf'
    [globalPrj]='globalPrj'
    [isValidHtml]='isValidHtml'
    [isValidTableReadonly]='isValidTableReadonly'
    [isValidTable]='isValidTable'
    [rowNumber]="(!rowIndex && rowIndex !== 0) ? selectedTableRowIndex : rowIndex"
    [selectedLanguage]='selectedLanguage'
    [selectedSectionId]='selectedSectionId'
    [style.width.%]="(tableConfChange.isWidthSpecifiedForSubBlocks && confChange.columnWidthPercentage) ? confChange.columnWidthPercentage : ''"
    [style]="(!rowIndex && rowIndex !== 0) ? 'position: static;' : 'position: static;display: table-cell;vertical-align: middle;'">
  </fb-rich-text-quill>
</ng-template>


<!-- Template to render grey columns in a table -->
<ng-template #greyColumn>
  <td class="angular-table-td" style="background-color: lightgrey;" ></td>
</ng-template>
