import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {
  ECaseNumberFormatterPipe,
  ECaseUtils,
  ECaseUtilsGlobal,
  PendingChangesGuard,
  RefreshDataService
} from 'synto-common';
import {Subscription} from 'rxjs/internal/Subscription';
import {DialogService} from '../../formbuilderForms/form/dialog.service';
import * as _ from 'lodash';
import {Platform} from '@angular/cdk/platform';


@Component({
  selector: 'fb-budget-t3',
  templateUrl: './fb-budget-t3.component.html',
  styleUrls: ['./fb-budget-t3.component.scss']
})
export class FbBudgetT3Component implements OnInit, OnDestroy {
  @Input() selectedLanguage: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() selectedSectionId: string;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  @Output() emitOutputEvent = new EventEmitter<any>();
  showDetails = false;
  selectedTableRowIndex = -1;
  copyBlockData: any;
  subscription: Subscription;
  globalComponent;
  topLevelBudgetItems = [];
  dataObjectForRecursiveSubCategorySelect: any = {};
  otherValue = {
    'otherValue': '',
    'label': {
      'en': 'Other',
      'fr': 'Autre'
    },
    'value': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID,
    'sortingKey': ECaseUtilsGlobal.OTHER_VALUE_TERM_ID
  }; // Labels are added in ngOnInit method
  originalRowData = [];
  NOT_ELIGIBLE_EXPENSES_BUDGET_ITEM = 33693;
  getDialogTextValue ={
    'fr':'La colonne Montant prévisionnel total se remplit de façon autonome en additionnant les montants inscrits dans les colonnes Montant prévisionnel An 1 et Montant prévisionnel An 2. Si vous constatez une erreur dans cette colonne, vous devez corriger les montants inscrits dans les colonnes précédentes.',
    'en': 'La colonne Montant prévisionnel total se remplit de façon autonome en additionnant les montants inscrits dans les colonnes Montant prévisionnel An 1 et Montant prévisionnel An 2. Si vous constatez une erreur dans cette colonne, vous devez corriger les montants inscrits dans les colonnes précédentes.'
  }
  dialogText = {
    '2032704_': {
      'en': 'This section includes the :<br>\n' +
        '- salaries and payroll taxes, employee benefits for the portion associated with this budget item;<br>\n' +
        '- professional fees, management, organization or coordination contracts with an external firm;<br>\n' +
        '- study costs of provenance and goodwill, or economic spinoffs;<br>\n' +
        '- operating costs, office supplies, telephone or computer costs;<br>\n' +
        '- costs related to volunteers (eg recruitment, promotion);<br>\n' +
        '- financial expenses (eg interest on loans, bookkeeping, bad debts);<br>\n' +
        '- dues or association membership fees.<br><br>\n' +
        'This section excludes :<br>\n' +
        '- depreciation, which should be shown in the "Other Expenses" section;<br>\n' +
        '- the portion of salaries allocated to other budget items (promotion/marketing/communication, programming costs, site and facilities management costs).',
      'fr': 'Cette section inclut les :<br>\n' +
        '- salaires et charges sociales, avantages sociaux des employés pour la portion associée à ce poste budgétaire;<br>\n' +
        '- honoraires professionnels, contrats de gestion, d’organisation ou de coordination de firme externe;<br>\n' +
        '- frais d’études de provenance et d’achalandage, ou de retombées économiques;<br>\n' +
        '- frais de fonctionnement, de fournitures de bureau, de téléphonie ou d’informatique;<br>\n' +
        '- frais liés aux bénévoles (ex. : recrutement, valorisation);<br>\n' +
        '- frais financiers (ex. : tenue de livres, créances douteuses);<br>\n' +
        '- cotisations ou frais d’adhésion à une association.<br><br>\n' +
        'Cette section exclut :<br>\n' +
        '- l\'amortissement, qui doit être présenté à  la section « Autres dépenses »;<br>\n' +
        '- la portion des salaires octroyés aux autres postes budgétaires (promotion/marketing/communication, frais de programmation, frais de gestion du site et des installations).'
    },
    '33692_': {
      'en': 'This section includes the :<br>\n' +
        '- salaries and payroll taxes, employee benefits for the portion associated with this budget item;<br>\n' +
        '- artistic production costs;<br>\n' +
        '- travel, accommodation and meal costs for artists, athletes, dignitaries;<br>\n' +
        '- artists\' fees;<br>\n' +
        '- fees and permits;<br>\n' +
        '- television and webcast production costs;<br>\n' +
        '- site improvements (e.g. temporary development, maintenance, repair, signage costs);<br>\n' +
        '- technical services (eg sound, lighting, sanitary services, timing, miscellaneous installations);<br>\n' +
        '- security services (eg personnel, accessories);<br>\n' +
        '- cost of goods sold (e.g. food, beverages, promotional items).',
      'fr': 'Cette section inclut les :<br>\n' +
        '- salaires et charges sociales, avantages sociaux des employés pour la portion associée à ce poste budgétaire;<br>\n' +
        '- frais de production artistique;<br>\n' +
        '- coûts de transports, d’hébergement et de repas des artistes, des athlètes, des dignitaires;<br>\n' +
        '- cachet des artistes;<br>\n' +
        '- droits et permis;<br>\n' +
        '- coûts de production télévisuelle et de webdiffusion;<br>\n' +
        '- améliorations du site (ex. : coûts d’aménagement temporaire, d’entretien, de réparation, de signalisation);<br>\n' +
        '- services techniques (ex. : son, éclairage, services sanitaires, chronométrage, installations diverses);<br>\n' +
        '- services de sécurité (ex. : personnel, accessoires);<br>\n' +
        '- les coûts des marchandises vendues (ex. : aliments, boissons, articles promotionnels).',
    },
    '33693_999999999': {
      'en': 'Example: the portion of salaries granted to other budget items (promotion, marketing and communication; programming costs; site and facilities management costs).',
      'fr': 'Exemple : la portion des salaires octroyés aux autres postes budgétaires (promotion, marketing et communication; frais de programmation; frais de gestion du site et des installations).',
    },
    '33693_33738': {
      'en': 'The following expenses are not eligible if they are related to fundraising activities: alcoholic beverages, food, liquor license, fees related to a draw, door prizes, or fundraisers.',
      'fr': 'Les dépenses suivantes ne sont pas admissibles si elles sont en lien avec des activités-bénéfice : boissons alcoolisées, nourriture, permis d’alcool, frais liés à un tirage, aux prix de présence, ou à des levées de fonds.',
    },
    '2032701_': {
      'en': 'This section must report all expenses related to promotion, marketing, as well as communication actions aimed at publicizing the festival or event.<br><br>\n' +
        'Promotion, marketing and communications expenses should be broken down by market in a later section.',
      'fr': 'Cette section doit faire état de l’ensemble des dépenses liées à la promotion, au marketing, ainsi qu’aux actions de communication visant à faire connaître le festival ou l’événement.<br><br>\n' +
        'Les dépenses liées à la promotion, au marketing et à la communication doivent être ventilées par marché dans une section ultérieure.',
    }
  };
  categoriesForWhichOtherAndIsTotalIsHidden = [2032704, 33692, 334511,10067,10072,10076,10082];
  extraRowLabel = {
    'en': 'Amount of unaudited goods and services sponsorships that correspond to this expenditure category.',
    'fr': 'Montant des commandites en biens et services non auditées qui correspond à cette catégorie de dépenses.'
  };
  subTotalTypeOneLabel = {
    'en': 'Subtotal of eligible expenses',
    'fr': 'Sous-total des dépenses admissibles'
  };
  subTotalTypeOneLabelNonEligible = {
    'en': 'Subtotal of ineligible expenses',
    'fr': 'Sous-total des dépenses non admissibles'
  };
  extraGlobalTotalLabel = {
    'en': 'Total of eligible expenses',
    'fr': 'Total des dépenses admissibles'
  };
  unauditedExpensesBudgetItemTermIds = [2066580, 2066581, 2066582, 2066583, 2066584];
  rowsWithoutExtraRows = [];
  rowsWithExtraRows = [];
  isEligibleExpensesSubTotalRowRequired = true;
  showProvisionalAmount = true;


  constructor(private translate: TranslateService,
              private matDialog: MatDialog,
              public platform: Platform,
              private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private refreshDataService: RefreshDataService,
              private dialogService: DialogService,
              private pendingChangesGuard: PendingChangesGuard) {
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
  }

  openDialog(dialogText) {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '600px',
      data: {dialog: dialogText, selectedLanguage: this.translate.getDefaultLang()}
    });
    console.log('donee');
    console.log(dialogText);
  }

  ngOnInit() {
    if(this.confChange.unauditedExpensesBudgetItemTermIds){
      this.unauditedExpensesBudgetItemTermIds = this.confChange.unauditedExpensesBudgetItemTermIds;
    }
    this.confChange.extraRowLabel = this.extraRowLabel;
    this.confChange.subTotalTypeOneLabel = this.subTotalTypeOneLabel;
    this.confChange.subTotalTypeOneLabelNonEligible = this.subTotalTypeOneLabelNonEligible;
    this.confChange.extraGlobalTotalLabel = this.extraGlobalTotalLabel;
    if(this.confChange.hasOwnProperty('isEligibleExpensesSubTotalRowRequired'))
      this.isEligibleExpensesSubTotalRowRequired = this.confChange.isEligibleExpensesSubTotalRowRequired
    if(this.confChange.hasOwnProperty('showProvisionalAmount'))
      this.showProvisionalAmount = this.confChange.showProvisionalAmount

    this.confChange.addOrRemoveExtraRows = (isAdd: Boolean) => {
      console.log('callled');
      this.addOrRemoveExtraRows(isAdd);
    };
    this.translate.langs.forEach((lang) => {
      if (lang && lang !== 'undefined') {
        this.otherValue.label[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.other', lang);
      }
    });
    if (!this.confChange.tableLabel) {
      this.confChange.tableLabel = {};
    }
    if (!this.bphChange) {
      this.bphChange = {};
      this.bphChange.error = {};
      this.bphChange.rows = [];
    }
    if (!this.bphChange.rows) {
      this.bphChange.rows = [];
    }

    /* this.subscription = this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
       this.selectedLanguage = params.lang;
     });*/

    if (this.globalConf.lovs['68'] && this.globalConf.lovs['68'].list) {
      const competitionBudgetItems = this.globalConf.lovs[this.confChange.selectedLovSQL].list.map(item => Number(item.data.BUDGET_ITEM_ID));
      this.topLevelBudgetItems = this.globalConf.lovs['68'].list.filter(item => competitionBudgetItems.includes(item.value) && !item.parentTermId).sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
      const dataFromDB = _.cloneDeep(this.bphChange.rows);
      this.prepopulateTable(true);
      this.rowsWithoutExtraRows = _.cloneDeep(this.bphChange.rows);
      this.bphChange.rows = _.cloneDeep(dataFromDB);
      this.prepopulateTable(false);
      this.addSubTotalTypeOneRows();
      this.bphChange.rows = _.cloneDeep(this.rowsWithoutExtraRows);
      this.originalRowData = _.cloneDeep(this.rowsWithoutExtraRows);
      if (this.bphChange.subTotalTypeOneEnabled) {
        this.confChange.addOrRemoveExtraRows(this.isEligibleExpensesSubTotalRowRequired);
      }
    }
  }

  addOrRemoveExtraRows(isAdd: Boolean) {
    console.log('callled again');
    this.bphChange.rows = _.cloneDeep(isAdd ? this.rowsWithExtraRows : this.rowsWithoutExtraRows);
    this.originalRowData = _.cloneDeep(this.bphChange.rows);
  }

  addSubTotalTypeOneRows() {
    console.log('callled again 2');
    const aRow = {'isEligibleExpensesSubTotalRow': true, 'column': 'category', 'provisionalAmount': {}, 'actualAmount': {},'actualAmounttotal': {}};
    this.bphChange.rows.forEach((row, i) => {
      this.bphChange.rows[i].uniqueRowIndex = i;
    });
    const clonedRows = _.cloneDeep(this.bphChange.rows);
    const uniqueRowIndexArray = this.bphChange.rows.filter(e => e.isTotal).map(e => e.uniqueRowIndex);
    uniqueRowIndexArray.forEach((index) => {
      const i = clonedRows.indexOf(clonedRows.filter(e => e.uniqueRowIndex === index)[0]);
      aRow['categoryId'] = clonedRows.filter(e => e.uniqueRowIndex === index)[0]?.category?.value;
      clonedRows.splice(i + 1, 0, _.cloneDeep(aRow));
    });
    this.rowsWithExtraRows = _.cloneDeep(clonedRows);
    this.rowsWithExtraRows.forEach((row, i) => {
      this.rowsWithExtraRows[i].uniqueRowIndex = i;
    });
  }

  findNestedSubCategories(category, previousSelectedSubCategory, previousSubCategories, excludeUnAuditedExpenses?) {
    const isSubCategoriesAvailable = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === previousSelectedSubCategory.value).length > 0;
    const subCat = this.originalRowData.filter(_row => !_row.isTotal && !_row.isEligibleExpensesSubTotalRow)
      .filter(item => item.category.value === category.value && item.subCategory.filter(_item => _item.value === this.otherValue.value).length > 0)
      .map(item => item.subCategory.filter(_item => _item.value === this.otherValue.value)[0]);
    this.otherValue.otherValue = subCat.length > 0 ? subCat[0].otherValue : '';

    let subCategories = ((isSubCategoriesAvailable && !this.categoriesForWhichOtherAndIsTotalIsHidden.includes(category.value)) ? this.globalConf.lovs['68'].list.filter(item => item.parentTermId === previousSelectedSubCategory.value).concat([this.otherValue]) : this.globalConf.lovs['68'].list.filter(item => item.parentTermId === previousSelectedSubCategory.value)).sort((a, b) => {
      if (a.sortingKey < b.sortingKey) {
        return -1;
      } else if (a.sortingKey > b.sortingKey) {
        return 1;
      }
      return 0;
    });
    if (subCategories.length > 0) {
      subCategories = (excludeUnAuditedExpenses ? subCategories.filter(item => !this.unauditedExpensesBudgetItemTermIds.includes(item.value)) : subCategories);
      console.log(subCategories);
      subCategories.forEach((subCategory) => {
        this.findNestedSubCategories(category, subCategory, previousSubCategories.concat([subCategory]), excludeUnAuditedExpenses);
      });
    } else {
      this.addRow(this.bphChange, this.confChange);
      const lastRow = this.bphChange.rows[this.bphChange.rows.length - 1];
      lastRow['category'] = category;
      lastRow['subCategory'] = previousSubCategories;
      let filteredValues = this.originalRowData.filter(_row => !_row.isTotal && !_row.isEligibleExpensesSubTotalRow).filter(_row => _row.category.value === category.value);
      for (const x of _.range(previousSubCategories.length)) {
        filteredValues = filteredValues.filter((_row) => {
          if (_row.subCategory[x]) {
            return _row.subCategory[x].value === previousSubCategories[x].value;
          } else {
            return false;
          }
        });
      }
      lastRow['actualAmount'] = filteredValues.length > 0 ? filteredValues[0].actualAmount : {'value': ''};
      lastRow['provisionalAmount'] = filteredValues.length > 0 ? filteredValues[0].provisionalAmount : {'value': ''};
      lastRow['actualAmounttotal'] = filteredValues.length > 0 ? filteredValues[0].actualAmounttotal : {'value': ''};

      lastRow['isExcludeFromTotal'] = Number(category.value) === this.NOT_ELIGIBLE_EXPENSES_BUDGET_ITEM || this.unauditedExpensesBudgetItemTermIds.includes(previousSelectedSubCategory.value);
      this.closeDetails(this.confChange, this.bphChange);
    }
  }

  prepopulateTable(excludeUnAuditedExpenses?: boolean) {
    this.originalRowData = _.cloneDeep(this.bphChange.rows);
    this.bphChange.rows = [];
    this.topLevelBudgetItems.forEach((budgetItem) => {
      const isSubCategoriesAvailable = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === budgetItem.value).length > 0;
      let subCategories = ((isSubCategoriesAvailable && !this.categoriesForWhichOtherAndIsTotalIsHidden.includes(budgetItem.value)) ? this.globalConf.lovs['68'].list.filter(item => item.parentTermId === budgetItem.value).concat([this.otherValue]) : this.globalConf.lovs['68'].list.filter(item => item.parentTermId === budgetItem.value)).sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });

      if (subCategories.length > 0) {
        subCategories = (excludeUnAuditedExpenses ? subCategories.filter(item => !this.unauditedExpensesBudgetItemTermIds.includes(item.value)) : subCategories);
        subCategories.forEach((subCategory) => {
          this.findNestedSubCategories(budgetItem, subCategory, [subCategory], excludeUnAuditedExpenses);
        });
      } else {
        this.addRow(this.bphChange, this.confChange);
        const lastRow = this.bphChange.rows[this.bphChange.rows.length - 1];
        lastRow['category'] = budgetItem;
        lastRow['subCategory'] = [{}];
        let filteredValues = this.originalRowData.filter(_row => !_row.isTotal && !_row.isEligibleExpensesSubTotalRow).filter(_row => _row.category.value === budgetItem.value);
        lastRow['actualAmount'] = filteredValues.length > 0 ? filteredValues[0].actualAmount : {'value': ''};
        lastRow['provisionalAmount'] = filteredValues.length > 0 ? filteredValues[0].provisionalAmount : {'value': ''};
        lastRow['actualAmounttotal'] = filteredValues.length > 0 ? filteredValues[0].actualAmounttotal : {'value': ''};
        lastRow['isExcludeFromTotal'] = Number(budgetItem.value) === this.NOT_ELIGIBLE_EXPENSES_BUDGET_ITEM;
        this.closeDetails(this.confChange, this.bphChange);
      }
    });
    if (this.bphChange.rows.length > 1) {
      this.bphChange.rows[this.bphChange.rows.length - 1].category = this.bphChange.rows[this.bphChange.rows.length - 2]?.category;
    }
    console.log(this.bphChange.rows);
  }

  onSubCategoryChange(event, dataObject) {
    (this.bphChange.rows[this.selectedTableRowIndex])['subCategory'][dataObject.level] = dataObject.subCategories.filter(item => item.value === event)[0];
    let subCategories = [];
    if (event >= ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
      subCategories = [];
    } else {
      const isSubCategoriesAvailable = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event).length > 0;
      subCategories = (isSubCategoriesAvailable ? this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event).concat([this.otherValue]) : this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event)).sort((a, b) => {
        if (a.sortingKey < b.sortingKey) {
          return -1;
        } else if (a.sortingKey > b.sortingKey) {
          return 1;
        }
        return 0;
      });
    }

    this.updateDataObjectForRecursiveSubCategorySelect(this.dataObjectForRecursiveSubCategorySelect, subCategories, 0, dataObject.level, event, false);
  }


  getWidthFromPlatform() {
    if (this.platform.ANDROID || this.platform.IOS) {
      return '100%';
    } else {
      return '50%';
    }
  }

  getTextLength(l): number {
    try {
      return l.toString().length;
    } catch (e) {
      return 0;
    }
  }

  getWordCount(str): number {
    try {
      return (str === '' ? 0 : str.split(' ').filter(item => item !== '').length);
    } catch (e) {
      return 0;
    }
  }

  preventMaxlengthViolation(tempObject, key, maxLength, index) {
    if (this.getTextLength(tempObject) >= maxLength) {
      tempObject = tempObject.toString().split('').slice(0, maxLength).join('');
      (this.bphChange.rows[index])[key].value = tempObject;
    }
    console.log('pppppppppppppppppp');
    console.log(index);
    console.log((this.bphChange.rows[index])['provisionalAmount'].value);
    console.log((this.bphChange.rows[index])['actualAmount'].value);
    (this.bphChange.rows[index])['actualAmounttotal'].value = Number((this.bphChange.rows[index])['provisionalAmount'].value) + Number((this.bphChange.rows[index])['actualAmount'].value);
    console.log((this.bphChange.rows[index])['actualAmounttotal'].value)
    console.log((this.bphChange.rows[index]))

    if (tempObject) {
      tempObject = tempObject.toString().replace(/[^0-9,.-]/g, '');
      (this.bphChange.rows[index])[key].value = tempObject;
    }
    this.pendingChangesGuard.isPristine = false;
    if (this.confChange.enableOutputEvent) {
      this.emitOutputEvent.emit(this.bphChange);
    }
  }

  getCategoryIndex(value): number {
    return (this.topLevelBudgetItems.indexOf(this.topLevelBudgetItems.filter(item => item.value === value)[0]) + 1);
  }

  getDialogText(value) {
    return value ? this.dialogText[value.toString()] : undefined;
  }

  updateDataObjectForRecursiveSubCategorySelect(dataObject, subCategories, currentLevel, requiredLevel, selectedSubCategory, keepCurrentSubCategoryValue) {
    if (currentLevel === requiredLevel) {
      dataObject['dataObject'] = {'subCategories': subCategories, 'level': (currentLevel + 1)};
      if (subCategories.length === 0 || selectedSubCategory >= ECaseUtilsGlobal.OTHER_VALUE_TERM_ID) {
        // do nothing
      } else {
        if (subCategories.length > 0 && !keepCurrentSubCategoryValue) {
          (this.bphChange.rows[this.selectedTableRowIndex])['subCategory'][(currentLevel + 1)] = {};
        }
      }
    } else {
      this.updateDataObjectForRecursiveSubCategorySelect(dataObject['dataObject'], subCategories, (currentLevel + 1), requiredLevel, selectedSubCategory, keepCurrentSubCategoryValue);
    }
  }

  getEligibleExpenseTotal(i, column, tcolumn, currency) {
    let r = this.getTotal(this.bphChange.rows, column, i - 1, tcolumn, this.bphChange.rows[i -1]);
    const nonEligibleExpense = this.bphChange.rows
      .filter(_row => !_row.isTotal && !_row.isEligibleExpensesSubTotalRow)
      .filter(_row => _row.category.value === this.bphChange.rows[i].categoryId && this.isIncludeSubCategory(_row.subCategory))[0];
    if (nonEligibleExpense && nonEligibleExpense['subCategory'][0].value != 2066583) {
      r = r - Number(nonEligibleExpense[column].value ? nonEligibleExpense[column].value : '0');
    }
    this.bphChange.rows[i][column].value = r;
    return this.eCaseNumberFormatterPipe.transform(r, !!currency, this.translate.getDefaultLang(), true);
  }

  getTotal(rows, column, j, tcolumn, row) {
    let r = 0;
    if ((typeof column !== 'undefined') && column !== '') {
      for (let i = j - 1; i >= 0; i--) {
        if (rows[i].isTotal && rows[i].column === tcolumn) {
          break;
        }
        if (!rows[i].isTotal && !rows[i].isEligibleExpensesSubTotalRow) {
          let newCellVal = 0;
          if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
            newCellVal = Math.round(parseFloat(rows[i][column]['value']));
          }
          r = r + newCellVal;
        }
        if (row) {
          if (!row[column]) {
            row[column] = {};
          }
          row[column]['value'] = r;
        }
      }
    } else {
      r = null;
    }
    return r;
  }

  getFormattedTotal(rows, column, j, tcolumn, row, currency) {
    const r = this.getTotal(rows, column, j, tcolumn, row);
    return this.eCaseNumberFormatterPipe.transform(r, !!currency, this.translate.getDefaultLang(), true);
  }

  isIncludeSubCategory(subCategories: any[]): boolean {
    let result = false;
    if (subCategories) {
      for (let i = 0; i < this.unauditedExpensesBudgetItemTermIds.length; i++) {
        result = subCategories.map(item => item.value).includes(this.unauditedExpensesBudgetItemTermIds[i]);
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  deleteRow(blockData, blockConf, i) {
    if (blockConf.isConfirmDelete) {
      blockConf.confirmDelete(this.globalPrj, this.globalConf, this.matDialog, this.translate, i, this, this.dialogService);
    } else {
      this.deleteTableRow(blockData, blockConf, i);
    }
  }

  deleteTableRow(blockData, blockConf, i) {
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (blockData.rows[i][subBlockName].value) {
            for (let iiTobd = 0; iiTobd < blockData.rows[i][subBlockName].value.length; iiTobd++) {
              if (blockData.rows[i][subBlockName].value[iiTobd] && blockData.rows[i][subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(blockData.rows[i][subBlockName].value[iiTobd].id);
              }
            }
          }
        }
      }
    }
    blockData.rows.splice(i, 1);
    this.reshuffleTable(blockData, blockConf);
    this.bphChange = _.cloneDeep(blockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
  }

  reshuffleTable(blockData, blockConf) {
    if (blockConf && blockConf.showGroupBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      if (blockData.rows.length === 0) {
        return;
      }
      const groupByColumns = blockConf.groupedBy.split(',');
      blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      for (let i = groupByColumns.length - 1; i >= 0; i--) {
        if (i - 1 >= 0) {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, groupByColumns[i - 1]);
        } else {
          this.reshuffleColumn(blockData, blockConf, groupByColumns[i], i, undefined);
        }
      }
    }
  }

  reshuffleColumn(blockData, blockConf, gbcolumn, k, gPreviouscolumn) {
    let oldCol = 0;
    const indexes = [];
    const groupColumn = gbcolumn.split('.')[0];
    blockData.rows = blockData.rows.sort((a, b) => {
      if (a.uniqueRowIndex < b.uniqueRowIndex) {
        return -1;
      } else if (a.uniqueRowIndex > b.uniqueRowIndex) {
        return 1;
      }
      return 0;
    });
    if (gPreviouscolumn) {
      eval('oldCol = blockData.rows[0].' + gbcolumn + ' +\'_\' +blockData.rows[0].' + gPreviouscolumn);
    } else {
      eval('oldCol = blockData.rows[0].' + gbcolumn);
    }
    let rowSpanRow: any = {};
    eval('rowSpanRow = blockData.rows[0].' + groupColumn);
    rowSpanRow.rowspan = 0;
    let newCol;
    for (let i = 0; i < blockData.rows.length; i++) {
      if (!blockData.rows[i].isTotal) {
        if (gPreviouscolumn) {
          eval('newCol = blockData.rows[i].' + gbcolumn + ' +\'_\' +blockData.rows[i].' + gPreviouscolumn);
        } else {
          eval('newCol = blockData.rows[i].' + gbcolumn);
        }
        console.log('groupColumn');
        console.log(groupColumn);
        if (oldCol !== newCol) {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=false');
          indexes.push(i);
          oldCol = newCol;
          eval('rowSpanRow = blockData.rows[i].' + groupColumn);
          rowSpanRow.rowspan = 1;
        } else {
          eval('blockData.rows[i].' + groupColumn + '.hideCell=true');
          rowSpanRow.rowspan++;
        }
      } else {
        rowSpanRow.rowspan++;
      }
    }

    const clonedData = _.cloneDeep(blockData.rows);
    ECaseUtils.groupBy(clonedData, item => item[groupColumn]['value']).forEach((groupedRow) => {
      groupedRow.forEach((row, index) => {
        ((clonedData[clonedData.indexOf(row)])[groupColumn])['hideCell'] = index !== 0;
      });
    });
    blockData.rows = _.cloneDeep(clonedData);
    indexes.push(blockData.rows.length + 1);
    let moved = 0;
    blockConf.subBlocks = [{'name': 'category'}, {'name': 'subCategory'}, {'name': 'actualAmount'}, {'name': 'provisionalAmount'}, {'name': 'actualAmount'}];
    for (let i = 0; i < blockConf.subBlocks.length; i++) {
      if (blockConf.subBlocks[i].name === groupColumn && !blockConf.subBlocks[i].noSubTotal) {
        for (let i = 0; i < indexes.length; i++) {
          blockData.rows.splice(indexes[i] + moved, 0, {
            'isTotal': true,
            'position': k,
            'column': groupColumn,
            'category': blockData.rows[indexes[i] + moved - 1]?.category
          });
          moved++;
        }
      }
    }
  }

  orderTable(blockData, blockConf) {
    if (blockConf && blockConf.showOrderBy) {
      blockData.rows = _.filter(blockData.rows, function(e) {
        return !e.isTotal;
      });
      const groupByColumns = blockConf.orderedBy.split(',');
      if (blockConf.orderedByTypes) {
        const groupByTypes = blockConf.orderedByTypes.split(',');
        blockData.rows = _.orderBy(blockData.rows, groupByColumns, groupByTypes);
      } else {
        blockData.rows = _.orderBy(blockData.rows, groupByColumns);
      }
    }
  }


  onCategoryChange(event, keepCurrentSubCategoryValue) {
    (this.bphChange.rows[this.selectedTableRowIndex])['category'] = this.topLevelBudgetItems.filter(item => item.value === event)[0];
    const isSubCategoriesAvailable = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event).length > 0;
    const subCategories = (isSubCategoriesAvailable ? this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event).concat([this.otherValue]) : this.globalConf.lovs['68'].list.filter(item => item.parentTermId === event)).sort((a, b) => {
      if (a.sortingKey < b.sortingKey) {
        return -1;
      } else if (a.sortingKey > b.sortingKey) {
        return 1;
      }
      return 0;
    });
    if (subCategories.length > 0 && !keepCurrentSubCategoryValue) {
      (this.bphChange.rows[this.selectedTableRowIndex])['subCategory'][0] = {};
    }
    this.dataObjectForRecursiveSubCategorySelect = {'subCategories': subCategories, 'level': 0};
  }

  cancel(blockConf, blockData) {
    blockConf.showDetails = false;
    this.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    this.refreshDataService.toggleShowSaveNavBar(true);
    this.bphChange = _.cloneDeep(this.copyBlockData);
    (this.globalPrj[this.selectedSectionId])[blockConf.name] = this.bphChange;
  }

  closeDetails(blockConf, blockData) {
    blockConf.showDetails = false;
    this.showDetails = false;
    this.orderTable(blockData, blockConf);
    this.reshuffleTable(blockData, blockConf);
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = true;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = false;
      } catch (e) {
      }
    }
    if (blockConf.isAddRowFunctionActive) {
      blockConf.addRow(this.globalPrj, this.globalConf, this, this.selectedLanguage);
    }
    if (blockConf.isEditRowFunctionActive) {
      if (typeof blockConf.editRow === 'function') {
        blockConf.editRow(this.globalPrj, this.globalConf, this, this.selectedTableRowIndex, blockData);
      }
    }
    const data: any = {};
    data.operation = 'Save Without Validation';
    // this.refreshDataService.saveForm(data);
    this.refreshDataService.toggleShowSaveNavBar(true);
  }

  getCleanedJson(json, isCleanError) {
    if (json === null || !json) {
      json = {};
    }
    if (json['pdf']) {
      json['pdf'] = {};
    }
    if (isCleanError) {
      if (json['error'] || typeof json['error'] === 'object') {
        json['error'] = {};
      }
      if (json['error_class'] === '' || json['error_class'] === 'error' || typeof json['error'] === 'string') {
        json['error_class'] = '';
      }
    }
    for (const key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      if (typeof json[key] === 'object') {
        this.getCleanedJson(json[key], isCleanError);
      }
    }
    return json;
  }

  ngOnDestroy(): void {
    this.confChange.showDetails = false;
    this.showDetails = false;
    // this.subscription.unsubscribe();
  }

  concatBudgetItemSubCategory(subCategories: any[]) {
    return subCategories.filter(item => item.label).map((item) => {
      if (item.otherValue && item.otherValue !== '') {
        return item.label[this.selectedLanguage] + ' / ' + item.otherValue;
      } else {
        return item.label[this.selectedLanguage];
      }
    }).join(' / ');
  }

  isIncludeOther(subCategories: any[]): boolean {
    return subCategories.filter(item => item.value === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID).length > 0;
  }

  getLevel(subCategories: any[]): number {
    return subCategories.indexOf(subCategories.filter(item => item.value === ECaseUtilsGlobal.OTHER_VALUE_TERM_ID)[0]);
  }

  addRow(blockData, blockConf) {
    this.dataObjectForRecursiveSubCategorySelect = {};
    // blockConf.isAddRowFunctionActive = true;
    blockConf.isEditRowFunctionActive = false;
    blockConf.showDetails = false;
    this.showDetails = false;
    this.confChange = _.cloneDeep(blockConf);
    this.bphChange = _.cloneDeep(blockData);
    this.copyBlockData = _.cloneDeep(this.bphChange);
    console.log('ddddddddddd');
    console.log(this.confChange);
    if (!this.confChange.tableLabel) {
      this.bphChange.globalShowDetail = false;
    }

    this.refreshDataService.toggleShowSaveNavBar(false);
    this.confChange = blockConf;
    blockData.rows = blockData.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(function(o) {
      return o.uniqueRowIndex ? o.uniqueRowIndex : 0;
    })) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(this.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    /*blockConf.subBlocks.forEach(function (entity) {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });*/
    aRow['category'] = {};
    aRow['subCategory'] = [];
    aRow['actualAmount'] = {};
    aRow['provisionalAmount'] = {};

    blockData.rows.push(aRow);
    blockData['selectedRowIndex'] = blockData.rows.length - 1;
    this.selectedTableRowIndex = blockData.rows.length - 1;
    blockData.selectedRow = aRow;
    blockConf.showDetails = true;
    this.showDetails = true;
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    blockConf.subBlocks.forEach(function(entity) {
      aRow[entity.name].globalShowDetail = true;
    });
    window.scrollTo(0, 0);
    this.bphChange = blockData;
    this.confChange = blockConf;
  }

  getGlobalTotal(rows, column, currency, excludeExtraRows?) {
    let r = 0;
    if ((typeof column !== 'undefined') && column !== '') {
      for (let i = 0; i < rows.length; i++) {
        if (!rows[i].isTotal && !rows[i].isEligibleExpensesSubTotalRow &&
          (excludeExtraRows || (!this.unauditedExpensesBudgetItemTermIds.includes(rows[i].subCategory[rows[i].subCategory.length - 1].value)
            && rows[i].category.value !== this.NOT_ELIGIBLE_EXPENSES_BUDGET_ITEM))) {
          let newCellVal = 0;
          if (rows[i][column] && !isNaN(parseInt(rows[i][column]['value'], 10))) {
            newCellVal = Math.round(parseFloat(rows[i][column]['value']));
          }
          r = r + newCellVal;
          const newTotalRow = {};
          newTotalRow['isTotal'] = true;
          newTotalRow['value'] = r;
        }
      }
    } else {
      r = null;
    }
    return this.eCaseNumberFormatterPipe.transform(r, !!currency, this.translate.getDefaultLang(), true);
  }

  editRow(blockData, blockConf, i) {
    blockConf.isAddRowFunctionActive = false;
    blockConf.isEditRowFunctionActive = true;
    this.selectedTableRowIndex = i;
    this.globalPrj.isConfirmationSaved = false;
    blockData.selectedRow = blockData.rows[i];
    blockConf.showDetails = true;
    this.showDetails = true;
    this.refreshDataService.toggleShowSaveNavBar(false);
    if (blockData.rows[i].category && blockData.rows[i].category.value) {
      this.dataObjectForRecursiveSubCategorySelect = {};
      this.onCategoryChange(blockData.rows[i].category.value, true);
      blockData.rows[i].subCategory.forEach((subcategory, index) => {
        const isSubCategoriesAvailable = this.globalConf.lovs['68'].list.filter(item => item.parentTermId === subcategory.value).length > 0;
        const subCategories = (isSubCategoriesAvailable ? this.globalConf.lovs['68'].list.filter(item => item.parentTermId === subcategory.value).concat([this.otherValue]) : this.globalConf.lovs['68'].list.filter(item => item.parentTermId === subcategory.value)).sort((a, b) => {
          if (a.sortingKey < b.sortingKey) {
            return -1;
          } else if (a.sortingKey > b.sortingKey) {
            return 1;
          }
          return 0;
        });
        this.updateDataObjectForRecursiveSubCategorySelect(this.dataObjectForRecursiveSubCategorySelect, subCategories, 0, index, subcategory.value, true);
      });
    }
    const globalArray = [];
    for (const x in this.globalPrj[this.selectedSectionId]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    for (let k = 0; k < globalArray.length; k++) {
      try {
        ((this.globalPrj[this.selectedSectionId])[globalArray[k]]).globalShowDetail = false;
        ((this.globalConf[this.selectedSectionId])[globalArray[k]]).showDetails = true;
      } catch (e) {
      }
    }
    blockData['selectedRowIndex'] = i;
    this.copyBlockData = _.cloneDeep(this.bphChange);
    window.scrollTo(0, 0);
  }

  convertNumberToNumericFormat(currency, value) {
    return value ? this.eCaseNumberFormatterPipe.transform(value, !!currency) : '';
  }


}
